import React from "react";
import classes from "./Button.module.scss";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const Button = ({ children, id, classesName }, props) => {
  const { t } = useTranslation();
  const lang = Cookies.get('i18next');
  return (
    <a
      href={`https://user.assetace.ltd/signup?lang=${lang}`}
      id={id && id}
      className={[classes.btnDefault, "no-select", classesName].join(" ")}
      {...props}
    >
      <div>{children ? children : t("btn_start")}</div>
    </a>
  );
};

export default Button;
